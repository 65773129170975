.douke-share-body {
  position: relative;
  padding-left: 20px;
  height: 100vh;
}
.des {
  margin-right: 20px;
  margin-bottom: 20px;
  font-size: 16px;
  font-family: PingFangSC-Regular, PingFang SC;
  color: #1b1b1b;
  line-height: 31px;
}
.title-box {
  width: 60%;
  padding-top: 50px;
  font-size: 24px;
  height: 127px;
  font-family: PingFangSC-Medium, PingFang SC;
  color: #1b1b1b;
  text-align: left;
}
.bg {
  right: 0;
  top: 20px;
  position: absolute;
  width: 123px;
  height: 147px;
}

.tips-box {
  position: relative;
  display: flex;
}
.tips {
  font-size: 18px;
  font-family: PingFangSC-Medium, PingFang SC;
  color: #1b1b1b;
  margin: 0 20px;
}
.adorn {
  position: absolute;
  left: 0;
  top: 0;
  width: 5px;
  height: 28px;
  background: #fe2c55;
  border-radius: 4px;
}
.content {
  /* width: 100%; */
  margin-top: 15px;
  padding: 15px 18px 28px;
  margin-right: 20px;
  background: #fafafa;
  border-radius: 16px;
  margin-bottom: 50px;
  text-align: left;

  font-size: 16px;
  font-family: PingFangSC-Regular, PingFang SC;
  color: rgba(27, 27, 27, 0.85);
  line-height: 37px;
}
.img-step {
  width: 100%;
  height: auto;
}

.qrcode-tip {
  margin: 40px 20px -20px 0;
  text-align: center;
  font-family: PingFangSC-Medium, PingFang SC;
  font-size: 16px;
  color: #1b1b1b;
}
.qrcode-box {
  text-align: center;
  margin-top: 28px;
  padding-bottom: 100px;
  margin-right: 20px;
}
.qrcode {
  width: 180px;
  height: auto;
}
