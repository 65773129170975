.tax-rules-body {
  background-color: white;
  min-height: 100vh;
  font-size: 16px;
  color: #1b1b1b;
  padding: 19px 10px 19px 16px;
  line-height: 24px;
}

.tax-rules-title {
  margin-bottom: 20px;
}
