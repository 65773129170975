.join-group-body {
  position: relative;
  padding-left: 20px;
  height: 100vh;
}

.join-group-title-box {
  width: 65%;
  padding-top: 50px;
  font-size: 24px;
  height: 127px;
  font-family: PingFangSC-Medium, PingFang SC;
  color: #1b1b1b;
  text-align: left;
}
.bg {
  right: 0;
  top: 20px;
  position: absolute;
  width: 123px;
  height: 147px;
}

.tips-box {
  position: relative;
  display: flex;
  margin-top: 20px;
}
.tips {
  font-size: 18px;
  font-family: PingFangSC-Medium, PingFang SC;
  color: #1b1b1b;
  margin-left: 20px;
}
.adorn {
  position: absolute;
  left: 0;
  top: 0;
  width: 5px;
  height: 28px;
  background: #fe2c55;
  border-radius: 4px;
}
.content {
  /* width: 100%; */
  margin-top: 15px;
  padding: 15px 18px 28px;
  margin-right: 20px;
  background: #fafafa;
  border-radius: 16px;
  margin-bottom: 50px;
  text-align: left;

  font-size: 16px;
  font-family: PingFangSC-Regular, PingFang SC;
  color: rgba(27, 27, 27, 0.85);
  line-height: 37px;
}
.qrcode-box {
  text-align: center;
  margin-top: 28px;
}
.qrcode {
  width: 180px;
  height: auto;
}
